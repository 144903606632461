import { getClassNamesFromArray } from '@common/utils';
import { Footer } from './footer';
import { ScrollToTop } from './scroll-to-top';
import { HeaderContainer } from './header/header-container';
import './styles.less';
const {
  useRef,
  useState
} = React;
const {
  ReactIcon: Icon,
  ReactIconButton: IconButton
} = svs.ui;
const {
  ItemsMenu,
  IconItem
} = svs.ui.ReactItemsMenu;
const {
  urlMapping,
  userSession
} = svs.core;
const isAdminOrSales = userSession.hasRole('RetailerSales') || userSession.hasRole('AdminRetailer');
const SiteWrapper = ({
  children,
  hasHeader,
  hasMenu
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const scrollContainerRef = useRef();
  return <React.Fragment>
      <ScrollToTop />
      {hasHeader && <HeaderContainer hasMenu={hasMenu} onToggleMenu={() => setMenuOpen(!isMenuOpen)} />}

      <div className={getClassNamesFromArray(['site-wrapper u-flex-grow-1', hasHeader && 'has-header'])}>
        <div className={getClassNamesFromArray(['items-menu-page-overlay', isMenuOpen && 'is-open'])} onClick={() => setMenuOpen(false)} />

        {hasMenu && <ItemsMenu branding="ombudsportalen" centered className={getClassNamesFromArray(['site-menu', isMenuOpen && 'is-open'])} customer="neutral">

            <div className="close-icon">
              <IconButton onClick={() => setMenuOpen(false)}>
                <Icon icon="close" />
              </IconButton>
            </div>

            <ul>
              <li>
                <IconItem alignment="vertical" Component="a" gutter={1} hasIconCircle hasUnderline={false} href={urlMapping.get('myBusinessHome')} iconName="nav-home" isSelected={location.pathname.startsWith(urlMapping.get('myBusinessHome'))} key="Min butik" label="Min butik" />
              </li>
              <li>
                <IconItem alignment="vertical" Component="a" gutter={1} hasIconCircle hasUnderline={false} href={urlMapping.get('newsHome')} iconName="news" isSelected={location.pathname.startsWith(urlMapping.get('newsHome'))} key="Aktuellt" label="Aktuellt" />
              </li>
              {isAdminOrSales && <li>
                  <IconItem alignment="vertical" Component="a" gutter={1} hasIconCircle hasUnderline={false} href={urlMapping.get('economyHome')} iconName="my-habits" isSelected={location.pathname.startsWith(urlMapping.get('economyHome'))} key="Ekonomi" label="Ekonomi" />
                </li>}
              <li>
                <IconItem alignment="vertical" Component="a" gutter={1} hasIconCircle hasUnderline={false} href={urlMapping.get('knowledgeHome')} iconName="game-history" isSelected={location.pathname.startsWith(urlMapping.get('knowledgeHome'))} key="Kunskap" label="Kunskap" />
              </li>
              <li>
                <IconItem alignment="vertical" Component="a" gutter={1} hasIconCircle hasUnderline={false} href={urlMapping.get('materialHome')} iconName="brands" isSelected={location.pathname.startsWith(urlMapping.get('materialHome'))} key="Material" label="Material" />
              </li>
              <li>
                <IconItem alignment="vertical" Component="a" gutter={1} hasIconCircle hasUnderline={false} href={urlMapping.get('spelkollHome')} iconName="var-spelkoll" isSelected={location.pathname.startsWith(urlMapping.get('spelkollHome'))} key="Spelkoll" label="Spelkoll" />
              </li>
              <li className="margin-top-4 padding-bottom-2">
                <a href="/whistleblower">Visselblåsning</a>
              </li>
              <li className="padding-bottom-2">
                <a href="/ombudssupport">Support</a>
              </li>
            </ul>
          </ItemsMenu>}

        <div className="u-flex u-flex-grow-1 u-flex-column scroll-container" ref={scrollContainerRef}>
          <div className="u-flex-grow-1">
            <div className="page-header-container hide-on-print" />
            {children}
          </div>
          <Footer isCenter={!hasMenu} />
        </div>
      </div>
    </React.Fragment>;
};
SiteWrapper.defaultProps = {
  hasHeader: true,
  hasMenu: true
};
export default SiteWrapper;